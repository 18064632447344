import React, { useState, useEffect, useMemo, Fragment } from "react";
import EllipseVerticleIcon from "../../../Static/RawImages/ellipsis-vertical-icon.svg";
import NoRecord from "../../../Components/NoRecord";
import { fetchCommonActivity } from "../../../API/agentApis";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";
import Paging from "../../../Components/Paging";
import { format, parse } from "date-fns";
import CallButton from "../../Components/CallButton";
import { findSourceOfLead } from "../../../utils/findSourceOfLead";
import { IconVariant } from "../../Components/iconVariants";
import useAgentStore from "../../../store/agent/agentStore";
import useForm from "../../../hooks/useForm";
import BorrowerSearch from "../../Components/BorrowerSearch";
import CustomerDetails from "../../Components/CustomerDetails";
import IncomingCall from "../../Components/IncomingCall";
import { commaFormat } from "../../../Functions/formatNumber";
import axios from "axios";
import PanelSumarry from "../../Components/PanelSumarry";
import useBorrowerInfo from "../../features/borrower-info/store";
import { getPrelitigationOrderStatus } from "../../../API/adminApis";

const typeOfLeads = [
  { label: "Search", value: "" },
  { label: "Replied on Whatsapp", value: "whatsapp_reply" },
  { label: "Replied on Email", value: "email_reply" },
  { label: "Promised to Pay on IVR", value: "pressed_1" },
  { label: "Tried Making Payment", value: "failed_payment" },
  { label: "Called on Helpline", value: "helpline_number" },
];

const communicationHistoryOptions = [
  {
    isGroup: false,
    label: "Search",
    value: "",
  },
  {
    isGroup: true,
    label: "SMS",
    options: [
      { label: "Link Clicked", value: "sms_blue" },
      { label: "Delivered", value: "sms_grey" },
      { label: "Not Delivered", value: "sms_red" },
      { label: "Not Send Yet", value: "sms_info" },
    ],
  },
  {
    isGroup: true,
    label: "Whtasapp",
    options: [
      { label: "Responded", value: "whatsapp_green" },
      { label: "Read", value: "whatsapp_blue" },
      { label: "Delivered", value: "whatsapp_grey" },
      { label: "Not on Whatsapp / Not Delivered", value: "whatsapp_red" },
      { label: "Not Send Yet", value: "whatsapp_info" },
    ],
  },
  {
    isGroup: true,
    label: "IVR",
    options: [
      { label: "Connected", value: "ivr_green" },
      { label: "Answered", value: "ivr_blue" },
      { label: "RNR", value: "ivr_grey" },
      { label: "WN or SW", value: "ivr_red" },
      { label: "Not Send Yet", value: "ivr_info" },
    ],
  },
  {
    isGroup: true,
    label: "Agent",
    options: [
      { label: "PTP / SETL / SUR", value: "agent_green" },
      { label: "Connected / No Agent Disposition / CB", value: "agent_blue" },
      { label: "RNR", value: "agent_grey" },
      { label: "WN or SW or RTP", value: "agent_red" },
      { label: "No Agent Disposition / Not Connected", value: "agent_info" },
    ],
  },
  {
    isGroup: true,
    label: "Email",
    options: [
      { label: "Responded", value: "email_green" },
      { label: "Opened", value: "email_blue" },
      { label: "Delivered", value: "email_grey" },
      { label: "Invalid Email / Bounce", value: "email_red" },
      { label: "Not Send Yet", value: "email_info" },
    ],
  },
];

const LIMIT_PER_PAGE = 20;

const dateTimeFormatter = (date, time) => {
  if (!date || !time) return "-- --- | --- --";
  // time => 14:53:59.214231
  let timeArr = time.split(":");
  let timeObject = parse(`${parseInt(timeArr[0])}:${parseInt(timeArr[1])}:${parseInt(timeArr[2])}`, "H:m:s", new Date());

  return `${format(new Date(date), "dd MMM")} | ${format(timeObject, "p")}`;
};

function NewImpLeads() {
  const userData = useAuthStore((state) => state.userData);
  const showAlert = useGeneralStore((state) => state.open_alert);
  const fetchDataFlag = useAgentStore((state) => state.call_common_activity_toggle);
  const reloadFlag = useAgentStore((state) => state.reload_now);
  const data = useAgentStore((state) => state.common_activity_data);
  const addCommonActivityData = useAgentStore((state) => state.addCommonActivityData);
  const addCustomerSourceOfLeadData = useAgentStore((state) => state.addCustomerSourceOfLeadData);
  const addCustomerId = useAgentStore((state) => state.addCustomerId);
  const toggleLeadCountFetchFlag = useAgentStore((state) => state.toggleLeadCountFetchFlag);

  const openBorrowerInfo = useBorrowerInfo((state) => state.openBorrowerInfo);

  const [filters, handleFilters, resetFilters] = useForm({
    sort_by: "",
    borrower: "",
    type_of_lead: "",
    communication_history: "",
    client_id: "",
  });

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState("NaN");

  const handlePagination = (type, target) => {
    if (type === "directValue") setCurrentPage(parseInt(target));
  };

  useEffect(() => {
    resetFilters();
  }, []);

  useEffect(() => {
    // Common Activity
    const request = axios.CancelToken.source();

    const fetchData = async () => {
      setLoading(true);

      const res = await fetchCommonActivity(
        {
          user_id: userData?.user_id,
          page: currentPage,
          ...filters,
          sort_by: !!filters?.sort_by ? filters?.sort_by : null,
        },
        // cancelToken
        request.token
      );
      if (res) {
        setPageTotal(Math.ceil(res?.total / LIMIT_PER_PAGE));

        addCommonActivityData({ common_activity_data: res?.data });
      }
      setLoading(false);
      toggleLeadCountFetchFlag();
    };

    fetchData();

    return () => {
      request.cancel();
    };
  }, [currentPage, fetchDataFlag, filters, reloadFlag]);

  const lenderOptions = useMemo(() => {
    const opts = !!userData?.clients?.length ? userData?.clients?.map((el) => ({ label: el?.client_name, value: el?.client_id })) : [];
    return [{ label: "Choose", value: "" }, ...opts];
  }, [userData]);

  return (
    <>
      <div className="pageContent imp-lead-layout">
        <PanelSumarry />

        <div className="imp-leads-content">
          <div className="table-scroll" style={{ height: "100%", minHeight: "30rem" }}>
            <table className="agent-filter-table">
              <thead>
                <tr>
                  <th className="align-top">
                    <BorrowerSearch value={filters?.borrower} onChange={(val) => handleFilters("borrower", val)} />
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Total Outstanding</div>
                    <select className="form-select" value={filters?.sort_by} onChange={(e) => handleFilters("sort_by", e.target.value)}>
                      {[
                        { label: "Choose", value: "" },
                        { label: "Highest to Lowest", value: "desc" },
                        { label: "Lowest to Highest", value: "asc" },
                      ]?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Type of Leads</div>
                    <select className="form-select" value={filters?.type_of_lead} onChange={(e) => handleFilters("type_of_lead", e.target.value)}>
                      {typeOfLeads?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Communication History</div>
                    <select
                      className="form-select"
                      value={filters?.communication_history}
                      onChange={(e) => handleFilters("communication_history", e.target.value)}
                    >
                      {communicationHistoryOptions.map(({ isGroup, label, options, value }, index) => (
                        <Fragment key={index}>
                          {!isGroup && <option value={value}> {label} </option>}

                          {isGroup && (
                            <optgroup label={label}>
                              {options?.map((option, indx) => (
                                <option key={`${index}-${indx}`} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </optgroup>
                          )}
                        </Fragment>
                      ))}
                    </select>
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Lender Name</div>
                    <select className="form-select" value={filters?.client_id} onChange={(e) => handleFilters("client_id", e.target.value)}>
                      {lenderOptions?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!!data?.length &&
                  data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-name">
                            <a
                              // onContextMenu={(e) => {
                              //   e.preventDefault();
                              //   if (!item?.customer) {
                              //     showAlert({ variant: "danger", msg: "Customer Id not Found" });
                              //     return;
                              //   }
                              //   addCustomerId(item?.customer);
                              //   addCustomerSourceOfLeadData(findSourceOfLead(item?.source_of_lead));
                              // }}
                              onClick={() => {
                                if (!item?.customer) {
                                  showAlert({ variant: "danger", msg: "Customer Id not Found" });
                                  return;
                                }
                                //  addCustomerSourceOfLeadData(findSourceOfLead(item?.source_of_lead));
                                openBorrowerInfo(item?.customer);
                                // history.push(`/agent/calls/customer_details/${item?.customer}`);
                              }}
                            >
                              {item?.customer_name || "----"}
                            </a>
                            {/* <Link to={`/agent/calls/customer_details/${item?.customer}`}></Link> */}
                          </div>
                          <div className="item-state item-label">
                            {item?.state || "----"} ({item?.city || "----"})
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-price">
                            <span className="me-1 tx-16">{`₹`}</span>
                            {commaFormat(item?.pos)}
                          </div>
                          <div className="bank-name item-label">{item?.client_name || "----"}</div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col">
                          <div className="item-text">{findSourceOfLead(item?.source_of_lead)}</div>
                          <div className="item-label">{dateTimeFormatter(item?.created_at, item?.updated_time)}</div>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{ display: "grid", gridTemplateColumns: "repeat(5,1fr)", gap: "0.8rem", maxWidth: "14rem" }}
                          className="item-col item-social-info"
                        >
                          {[
                            { icon: "ivr", variant: item?.customer_communication_history?.ivr_data },
                            { icon: "sms", variant: item?.customer_communication_history?.sms_data },
                            { icon: "email", variant: item?.customer_communication_history?.email_data },
                            { icon: "whatsapp", variant: item?.customer_communication_history?.whatsapp_data },
                            { icon: "agent", variant: item?.customer_communication_history?.agent_data },
                          ].map(({ icon, variant }, indx) => (
                            <IconVariant key={indx} icon={icon} variant={variant} index={`${index}_${indx}`} />
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="item-col item-col-btn justify-content-end">
                          <div className="item-btn">
                            <CallButton customer_mobile_number={item?.to_number || ""} />
                            <span>{item?.last_call_disposition || "----"}</span>
                          </div>

                          <div className="dropdown verticle-ellipse">
                            <a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src={EllipseVerticleIcon} />
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a onClick={() => openBorrowerInfo(item?.customer)} className="dropdown-item">
                                  Add Disposition
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {!!data?.length && (
          <div className="pb-3 mt-4">
            <Paging currentPage={currentPage} pageTotal={pageTotal} handlePagination={handlePagination} />
          </div>
        )}
      </div>
      {!loading && !data?.length && <NoRecord />}
      {/* {loading && <ContentLoader />} */}

      <IncomingCall />
      <CustomerDetails />
    </>
  );
}

export default NewImpLeads;
