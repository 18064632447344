import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { FaEnvelope, FaIndianRupeeSign, FaPhone } from "react-icons/fa6";
import useBorrowerInfo from "../../store";
import AnalysisCard from "./analysis-card";
import { getPaymentReview } from "../../../../../apis";
import { IoLogoWhatsapp } from "react-icons/io";

export default function PaymentStatus() {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);

    const isLoading = useBorrowerInfo((state) => state.isAnalysisLoading);
    const data = useBorrowerInfo((state) => state.data);

    const toggle = () => setIsOpen((prev) => !prev);

    const fetchData = async () => {
        if (!data?.data?.loan_account_no) {
            setDetails([]);
            return;
        }

        setLoading(true);

        const res = await getPaymentReview({ lan: data?.data?.loan_account_no });

        if (res.success) {
            setDetails(res?.data?.data?.data || []);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (isOpen && !!data) fetchData();
    }, [data, isOpen]);

    return (
        <>
            <AnalysisCard onClick={toggle} title="Payment Status" isLoading={isLoading} tag="" content="" icon={FaIndianRupeeSign} />

            <Offcanvas style={{ width: "350px" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Payment Analysis</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0">
                    {loading && <p className="text-muted m-0 p-5 text-center">Loading...</p>}
                    {!loading && !details?.length && <p className="text-muted m-0 p-5 text-center">No Data Found</p>}

                    {!loading && !!details?.length && (
                        <div>
                            {details.map((item, index) => (
                                <div key={index} style={{ borderBottom: "1px dashed #ddd" }} className="d-flex flex-column gap-2 py-3">
                                    {[
                                        [FaPhone, item?.call_ai_tag],
                                        [FaEnvelope, item?.email_tag],
                                        [IoLogoWhatsapp, item?.whatsapp_tag],
                                        [
                                            (props) => (
                                                <svg
                                                    {...props}
                                                    style={{ width: "20px", objectFit: "contain" }}
                                                    fill="currentColor"
                                                    viewBox="0 0 576 530"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M69 448.536C69 433.625 81.0883 421.536 96 421.536H315C329.912 421.536 342 433.625 342 448.536V472.536H385C399.912 472.536 412 484.625 412 499.536V529.536H0V499.536C0 484.625 12.0883 472.536 27 472.536H69V448.536ZM96 436.536H315C321.627 436.536 327 441.909 327 448.536V462.536H84V448.536C84 441.909 89.3726 436.536 96 436.536Z"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M110.732 275.772C89.3407 254.8 89.0013 220.458 109.973 199.067C130.946 177.677 165.288 177.337 186.678 198.309L195.789 207.241L299.064 101.905L289.953 92.9728C268.562 72.0007 268.223 37.6587 289.195 16.2678C310.167 -5.12299 344.509 -5.46242 365.9 15.5097L484.397 131.687C505.788 152.659 506.127 187.001 485.155 208.392C468.033 225.856 442 229.288 421.378 218.548L401.538 238.785L562.558 396.654C580.108 413.859 580.386 442.034 563.18 459.583C545.975 477.132 517.8 477.411 500.251 460.205L339.23 302.336L314.827 327.227C325.972 347.632 323.055 373.728 305.933 391.192C284.961 412.583 250.619 412.922 229.228 391.95L110.732 275.772ZM303.612 312.954L402.686 211.902L307.716 118.791L208.642 219.843L303.612 312.954ZM512.852 447.352L351.832 289.483L388.936 251.638L549.957 409.507C560.407 419.753 560.573 436.531 550.327 446.982C540.081 457.432 523.303 457.598 512.852 447.352Z"
                                                    />
                                                </svg>
                                            ),
                                            item?.online_tag,
                                        ],
                                    ].map(([Icon, status], idx) => (
                                        <div key={`${idx}-${index}`} className="d-flex align-items-center gap-2">
                                            <span style={{ width: "20px", height: "20px", color: "#666" }}>
                                                <Icon style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                            </span>

                                            <span className="tx-16">{status}</span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
