import React, { useState, useEffect, useMemo } from "react";
import EllipseVerticleIcon from "../../../Static/RawImages/ellipsis-vertical-icon.svg";
import NoRecord from "../../../Components/NoRecord";
import ContentLoader from "../../../Components/ContentLoader";
import { fetchAgentWisePtp } from "../../../API/agentApis";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";
import Paging from "../../../Components/Paging";
import { format, parse } from "date-fns";
import DatePicker from "../../../ui/forms/DatePicker";
import CallButton from "../../Components/CallButton";
import { findSourceOfLead } from "../../../utils/findSourceOfLead";
import { IconVariant } from "../../Components/iconVariants";
import WhatsappButton from "../../Components/WhatsappButton";
import useAgentStore from "../../../store/agent/agentStore";
import BorrowerSearch from "../../Components/BorrowerSearch";
import useForm from "../../../hooks/useForm";
import CustomerDetails from "../../Components/CustomerDetails";
import IncomingCall from "../../Components/IncomingCall";
import { commaFormat } from "../../../Functions/formatNumber";
import useBorrowerInfo from "../../features/borrower-info/store";

const LIMIT_PER_PAGE = 20;

const communicationHistoryOptions = [
  { label: "Search", value: "", disabled: false },
  //
  { label: "------ SMS -------", value: "sms_section", disabled: true },
  { label: "Link Clicked", value: "sms_blue", disabled: false },
  { label: "Delivered", value: "sms_grey", disabled: false },
  { label: "Not Delivered", value: "sms_red", disabled: false },
  { label: "Not Send Yet", value: "sms_info", disabled: false },
  //
  { label: "------ Whatsapp -------", value: "whatsapp_section", disabled: true },
  { label: "Responded", value: "whatsapp_green", disabled: false },
  { label: "Read", value: "whatsapp_blue", disabled: false },
  { label: "Delivered", value: "whatsapp_grey", disabled: false },
  { label: "Not on Whatsapp / Not Delivered", value: "whatsapp_red", disabled: false },
  { label: "Not Send Yet", value: "whatsapp_info", disabled: false },
  //
  { label: "------ IVR -------", value: "ivr_section", disabled: true },
  { label: "Connected", value: "ivr_green", disabled: false },
  { label: "Answered", value: "ivr_blue", disabled: false },
  { label: "RNR", value: "ivr_grey", disabled: false },
  { label: "WN or SW", value: "ivr_red", disabled: false },
  { label: "Not Send Yet", value: "ivr_info", disabled: false },
  //
  { label: "------ Agent -------", value: "agent_section", disabled: true },
  { label: "PTP / SETL / SUR", value: "agent_green", disabled: false },
  { label: "Connected / No Agent Disposition / CB", value: "agent_blue", disabled: false },
  { label: "RNR", value: "agent_grey", disabled: false },
  { label: "WN or SW or RTP", value: "agent_red", disabled: false },
  { label: "No Agent Disposition / Not Connected", value: "agent_info", disabled: false },
  //
  { label: "------ Email -------", value: "email_section", disabled: true },
  { label: "Responded", value: "email_green", disabled: false },
  { label: "Opened", value: "email_blue", disabled: false },
  { label: "Delivered", value: "email_grey", disabled: false },
  { label: "Invalid Email / Bounce", value: "email_red", disabled: false },
  { label: "Not Send Yet", value: "email_info", disabled: false },
  //
];

function PTPLeadsPage() {
  const userData = useAuthStore((state) => state.userData);
  const showAlert = useGeneralStore((state) => state.open_alert);
  const addCustomerSourceOfLeadData = useAgentStore((state) => state.addCustomerSourceOfLeadData);
  const addCustomerId = useAgentStore((state) => state.addCustomerId);
  const reloadFlag = useAgentStore((state) => state.reload_now);
  const toggleLeadCountFetchFlag = useAgentStore((state) => state.toggleLeadCountFetchFlag);
  const openBorrowerInfo = useBorrowerInfo((state) => state.openBorrowerInfo);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState("NaN");

  const [filters, handleFilters, resetFilters] = useForm({
    sort_by: "",
    borrower: "",
    date: null,
    communication_history: "",
    client_id: "",
  });

  const handlePagination = (type, target) => {
    if (type === "directValue") setCurrentPage(parseInt(target));
  };

  const fetchData = async () => {
    setLoading(true);

    const res = await fetchAgentWisePtp({
      user_id: userData?.user_id,
      page: currentPage,
      ...filters,
      sort_by: !!filters?.sort_by ? filters?.sort_by : null,
      date: !!filters?.date ? format(new Date(filters?.date), "yyyy-MM-dd") : null,
    });
    if (res) {
      setPageTotal(Math.ceil(res?.total / LIMIT_PER_PAGE));
      setData(res?.data);
    } else {
      showAlert({ variant: "danger", msg: "Unable to fetch Data" });
    }
    setLoading(false);
    toggleLeadCountFetchFlag();
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, filters, reloadFlag]);

  useEffect(() => {
    resetFilters();
  }, []);

  const lenderOptions = useMemo(() => {
    const opts = !!userData?.clients?.length ? userData?.clients?.map((el) => ({ label: el?.client_name, value: el?.client_id })) : [];
    return [{ label: "Choose", value: "" }, ...opts];
  }, [userData]);

  return (
    <>
      <div className="pageContent imp-lead-layout">
        <div className="imp-leads-content mt-0">
          <div className="table-scroll" style={{ height: "100%", minHeight: "30rem" }}>
            <table className="agent-filter-table">
              <thead>
                <tr>
                  <th className="align-top">
                    <BorrowerSearch value={filters?.borrower} onChange={(val) => handleFilters("borrower", val)} />
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Total Oustanding</div>
                    <select className="form-select" value={filters?.sort_by} onChange={(e) => handleFilters("sort_by", e.target.value)}>
                      {[
                        { label: "Choose", value: "" },
                        { label: "Highest to Lowest", value: "desc" },
                        { label: "Lowest to Highest", value: "asc" },
                      ]?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Date</div>
                    <DatePicker label="Choose" value={filters?.date} onChange={(val) => handleFilters("date", val)} />
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Communication History</div>
                    <select
                      className="form-select"
                      value={filters?.communication_history}
                      onChange={(e) => handleFilters("communication_history", e.target.value)}
                    >
                      {communicationHistoryOptions.map(({ label, value, disabled }, index) => (
                        <option key={index} value={value} disabled={disabled}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Lender Name</div>
                    <select className="form-select" value={filters?.client_id} onChange={(e) => handleFilters("client_id", e.target.value)}>
                      {lenderOptions?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  !!data?.length &&
                  data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-name">
                            <a
                              onClick={() => {
                                if (!item?.customer) {
                                  showAlert({ variant: "danger", msg: "Customer Id not Found" });
                                  return;
                                }
                                addCustomerSourceOfLeadData(findSourceOfLead(item?.source_of_lead));
                                openBorrowerInfo(item?.customer);
                              }}
                            >
                              {item?.customer_name || "----"}
                            </a>
                          </div>
                          <div className="item-state item-label">
                            {item?.state || "----"} ({item?.city || "----"})
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-price">
                            <span className="me-1 tx-16">{`₹`}</span>
                            {commaFormat(item?.pos)}
                          </div>
                          <div className="bank-name item-label">{item?.client_name || "----"}</div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col">
                          <div className="item-text">{findSourceOfLead(item?.source_of_lead) || "----"}</div>
                          <div className="item-label">{!!item?.ptp_date ? format(new Date(item?.ptp_date), "dd MMM yy") : "-- --- --"}</div>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{ display: "grid", gridTemplateColumns: "repeat(5,1fr)", gap: "0.8rem", maxWidth: "14rem" }}
                          className="item-col item-social-info"
                        >
                          {[
                            { icon: "ivr", variant: item?.customer_communication_history?.ivr_data },
                            { icon: "sms", variant: item?.customer_communication_history?.sms_data },
                            { icon: "email", variant: item?.customer_communication_history?.email_data },
                            { icon: "whatsapp", variant: item?.customer_communication_history?.whatsapp_data },
                            { icon: "agent", variant: item?.customer_communication_history?.agent_data },
                          ].map(({ icon, variant }, indx) => (
                            <IconVariant key={indx} icon={icon} variant={variant} index={`${index}_${indx}`} />
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="item-col item-col-btn">
                          <div className="item-btn">
                            <CallButton customer_mobile_number={item?.mobile_number || ""} />
                            <span>{item?.last_call_disposition || "----"}</span>
                          </div>
                          <div className="item-btn">
                            <WhatsappButton whatsapp_no={item?.mobile_number} />
                            <span>{item?.last_whatsapp_status || "----"}</span>
                          </div>
                          <div className="dropdown verticle-ellipse">
                            <a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src={EllipseVerticleIcon} />
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  disabled={!item?.customer}
                                  onClick={() => {
                                    openBorrowerInfo(item?.customer);
                                  }}
                                  className="dropdown-item"
                                >
                                  Add Disposition
                                </button>
                              </li>
                              <li>
                                <button className="dropdown-item">Send Information</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {!loading && !!data?.length && (
          <div className="pb-3 mt-4">
            <Paging currentPage={currentPage} pageTotal={pageTotal} handlePagination={handlePagination} />
          </div>
        )}
      </div>

      {!loading && !data?.length && <NoRecord />}
      {loading && <ContentLoader />}

      <IncomingCall />
      <CustomerDetails />
    </>
  );
}

export default PTPLeadsPage;
