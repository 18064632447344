import React, { useState } from "react";
import useAgentStore from "../../../../store/agent/agentStore";
import { searchCustomer } from "../../../../API/agentApis";
import { toast } from "react-toastify";
import { maskString } from "../../../../Functions/mask";
import { commaFormat } from "../../../../Functions/formatNumber";

import classes from "./style.module.css";
import { isNumber, isValidEmail } from "../../../../Functions/validations";
import useBorrowerInfo from "../../../features/borrower-info/store";

const searchFilters = [
  { label: "Number", value: "number" },
  { label: "LAN", value: "lan" },
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Ref", value: "ref" },
  { label: "Alt", value: "alt" },
];

export default function GlobalSearch() {
  const addCustomerId = useAgentStore((state) => state.addCustomerId);
  const openBorrowerInfo = useBorrowerInfo((state) => state.openBorrowerInfo);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [message, setMessage] = useState("");

  const [searchType, setSearchType] = useState("number");

  const closeSuggestions = () => {
    setShowSuggestions(false);
    setSuggestions([]);
    setSearchText("");
  };

  const searchCustomerBy = async () => {
    setShowSuggestions(true);
    setMessage("");
    setSuggestionsLoading(true);
    try {
      const res = await searchCustomer({
        search: searchText,
        search_type: searchType,
      });

      if (!!res?.message) setMessage(res?.message);
      setSuggestions(res?.results || []);
    } catch (error) {
      console.log(error);
      toast.error("Search failed");
    }
    setSuggestionsLoading(false);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            searchCustomerBy();
          }}
          className={classes.search}
        >
          <button className={classes.search_btn} type="submit">
            <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
              <g fill="#1A1A1A" fillRule="evenodd" opacity=".419">
                <path d="M14.953 23.719a8.766 8.766 0 1 1 0-17.532 8.766 8.766 0 0 1 0 17.532zm0-2.063a6.703 6.703 0 1 0 0-13.406 6.703 6.703 0 0 0 0 13.406z" />
                <path d="M26.51 25.052a1.031 1.031 0 0 1-1.458 1.458l-5.362-5.362a1.031 1.031 0 0 1 1.458-1.458l5.362 5.362z" />
              </g>
            </svg>
          </button>

          <input
            type="text"
            value={searchText}
            onChange={(e) => {
              const val = e.target.value;

              if (isValidEmail(val)) setSearchType("email");
              else if (isNumber(val)) setSearchType("number");
              else setSearchType("name");

              setSearchText(val);
            }}
            className={classes.search_input}
            placeholder="Type here to search..."
          />

          {!!searchText?.length && (
            <button type="button" onClick={closeSuggestions} className={classes.clear_btn}>
              <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.488155 0.488155C1.13903 -0.162718 2.1943 -0.162718 2.84518 0.488155L10 7.64298L17.1548 0.488155C17.8057 -0.162718 18.861 -0.162718 19.5118 0.488155C20.1627 1.13903 20.1627 2.1943 19.5118 2.84518L12.357 10L19.5118 17.1548C20.1627 17.8057 20.1627 18.861 19.5118 19.5118C18.861 20.1627 17.8057 20.1627 17.1548 19.5118L10 12.357L2.84518 19.5118C2.1943 20.1627 1.13903 20.1627 0.488155 19.5118C-0.162718 18.861 -0.162718 17.8057 0.488155 17.1548L7.64298 10L0.488155 2.84518C-0.162718 2.1943 -0.162718 1.13903 0.488155 0.488155Z"
                  fill="#606060"
                />
              </svg>
            </button>
          )}
        </form>
        <div className={classes.search_filter}>
          <select className={classes.dropdown} value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            {searchFilters.map(({ label, value }, index) => (
              <option value={value} key={`${index}_${value}`}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div
          className={classes.suggestion_container}
          style={{
            display: showSuggestions && !!searchText ? "block" : "none",
          }}
        >
          {!!message && !suggestions?.length && !suggestionsLoading && (
            <p style={{ whiteSpace: "pre-line" }} className="text-center py-3 px-3">
              {message}
            </p>
          )}
          {suggestionsLoading && <p className="text-center py-3">Loading...</p>}
          {!message && !suggestionsLoading && !suggestions?.length && <p className="text-center py-3">Not Data Found</p>}

          {!suggestionsLoading &&
            !!suggestions?.length &&
            suggestions?.map((item, index) => (
              <a
                key={index}
                // onContextMenu={(e) => {
                //     addCustomerId(item?.customer_id);
                //     closeSuggestions();
                //   }}
                onClick={() => {
                  openBorrowerInfo(item?.customer_id);
                  closeSuggestions();
                }}
                style={{ display: "block" }}
                className="search-item-list py-3 px-4"
              >
                <div className="row-list-item">
                  <div className="cl-left">
                    <div className="item-name tx-14" style={{ cursor: "pointer" }}>
                      {item?.customer_name || ""} <span className="item-acc-no">({maskString(item?.loan_account_no)})</span>
                    </div>
                    <div className="item-state">
                      {item?.customer_state || "----"} ({item?.customer_city || "----"})
                    </div>
                  </div>
                  <div className="cl-right">
                    <p className="mb-0 tx-14">
                      <span className="sr-loan-amt d-block">Rs. {commaFormat(item?.loan_amount)}</span>
                      <span className="sr-bank-name d-block text-black">{item?.client_name || "-----"}</span>
                    </p>
                  </div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </>
  );
}
