import { format } from "date-fns";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { postDispositionData } from "../../../API/agentApis";
import useCallCustomer from "../../../hooks/useCallCustomer";
import useForm from "../../../hooks/useForm";
import useAgentStore from "../../../store/agent/agentStore";
import useGeneralStore from "../../../store/generalStore";
import DatePicker from "../../../ui/forms/DatePicker";
import { handleNotifications } from "../../../utils/handleNotification";

const dispOptions = [
  {
    label: "",
    options: [
      { value: "PTP", label: "Promise To Pay (PTP)" },
      { value: "PAID", label: "Fully PAID" },
      { value: "P-PAID", label: "Partially PAID" },
      { value: "RTP", label: "Refuse To Pay (RTP)" },
      { value: "WN", label: "Wrong Number (WN)" },
      { value: "VEHICLE_LOST", label: "VEHICLE LOST (VEHICLE_LOST)" },
      { value: "VEHICLE_ALREADY_SURRENDERED", label: "VEHICLE ALREADY SURRENDERED" },
      { value: "READY_TO_SURRENDER_VEHICLE", label: "READY TO SURRENDER VEHICLE" },
    ],
  },
  {
    label: "",
    options: [
      { value: "SW", label: "Switch Off (SW)" },
      { value: "RNR", label: "Ringing No Response (RNR)" },
    ],
  },
  {
    label: "",
    options: [
      { value: "DND", label: "Do Not Disturb (DND)" },
      { value: "BPTP", label: "Broken PTP (BPTP)" },
      { value: "DIS", label: "Dispute (DIS)" },
      { value: "CB", label: "Call Back (CB)" },
      { value: "LANG", label: "Language Barrier (LANG)" },
      { value: "DECEASED", label: "Deceased (DECEASED)" },
      { value: "SUR", label: "Surrender (SUR)" },
    ],
  },
];

const flattenedDispOptions = dispOptions?.map(({ options }) => options).flat();

export default function PendingDispAddDispForm({ data = null, show, setShow, setData = () => {} }) {
  const reload = useAgentStore((state) => state.reload);
  const showAlert = useGeneralStore((state) => state.open_alert);
  const { callCustomer, callTypes } = useCallCustomer();

  const [loading, setLoading] = useState(false);

  const [dispForm, handleDispForm, resetDispForm] = useForm({
    disposition: "",
    ptp_amount: "",
    paid_amount: "",
    ptp_date: null,
    remark: "",
    alt_contact_number: "",
  });

  const closeModal = () => {
    setShow(false);
    resetDispForm();
    setData(null);
  };

  const addDisposition = async () => {
    setLoading(true);

    const res = await postDispositionData({
      customer: data?.customer?.customer_id,
      loan_account_no: data?.customer?.loan_account_no ? data?.customer?.loan_account_no : null,
      batch_id: data?.batch_id ? data?.batch_id : null,
      product_name: data?.customer?.product_name ? data?.customer?.product_name : null,
      due_agmt_no: data?.customer?.loan_account_no ? data?.customer?.loan_account_no : null,
      customer_name: data?.customer?.customer_name ? data?.customer?.customer_name : null,
      mobile_number: data?.To ? data?.To : null,
      contact_date: !!data?.DateUpdated ? format(new Date(data?.DateUpdated), "yyyy-MM-dd") : null,
      due_amount: data?.customer?.loan_amount ? data?.customer?.loan_amount : 0,
      payment_mode: null,
      next_action: null,

      disposition: dispForm?.disposition,
      alt_contact_number: dispForm.alt_contact_number ? dispForm.alt_contact_number : null,
      remark: dispForm.remark ? dispForm.remark : null,
      ptp_amount: dispForm.ptp_amount ? Number(dispForm.ptp_amount) : 0,
      ptp_date: dispForm.ptp_date ? format(new Date(dispForm.ptp_date), "yyyy-MM-dd") : null,
      paid_amount: dispForm.paid_amount ? Number(dispForm.paid_amount) : 0,

      settlement_amount: 0,
      number_of_emi: 0,
      settlement_emi_data: null,

      agency_name: "The Medius",
      channel: "HUMAN_CALL",
      idle_time: 0,
      chance_of_payment: 0,
    });

    if (res) {
      let message = handleNotifications({
        customer_mobile_number: data?.To,
        message: res?.success_message,
        notification_type: res?.notification_type,
        callCustomer: () => callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number: data?.To }),
      });

      showAlert({
        variant: res?.variant === "yellow" ? "warning" : res?.variant === "red" ? "danger" : res?.variant === "green" ? "success" : "info",
        msg: message,
      });
      reload();
      closeModal();
    } else showAlert({ variant: "danger", msg: "Failed to Add Disposition" });

    setLoading(false);
  };

  return (
    <>
      <Modal className="popup-style-one disp-popup" size="md" show={show}>
        <Modal.Header>
          <Modal.Title>Add Disposition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-content min-height-content">
            <div className="item-cl bg-white px-2 rounded-8 h-100">
              <div className="item-cl-head">
                <div className="row-field">
                  <div className="filter-label space-mb-8">Disposition</div>
                  <Select
                    placeholder="Select"
                    isClearable={true}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        padding: "0.2rem 0",
                        paddingLeft: "0.4rem",
                        border: "1px solid #dddddd !important",
                        borderRadius: "6px",
                        backgroundColor: state.isDisabled ? "#f1f1f1" : "#fff",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#E0E7E9" : state.isFocused ? "#f4f6f8" : "white",
                        color: !state.isSelected && state.isDisabled ? "#aaa" : "#555",
                        zIndex: "999",
                      }),
                    }}
                    options={dispOptions}
                    value={flattenedDispOptions?.find((el) => el?.value === dispForm?.disposition) || ""}
                    onChange={(val) => handleDispForm("disposition", !!val?.value ? val?.value : "")}
                    components={{
                      Group: (props) => (
                        <div style={{ borderBottom: `1px solid #e0e0e0` }}>
                          <components.Group {...props} />
                        </div>
                      ),
                    }}
                  />
                </div>

                {["PTP"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Amount</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={dispForm?.ptp_amount}
                      onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("ptp_amount", e.target.value) : {})}
                    />
                  </div>
                )}

                {["PAID", "P-PAID"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Amount</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={dispForm?.paid_amount}
                      onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("paid_amount", e.target.value) : {})}
                    />
                  </div>
                )}

                {["WN"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Alternate Number</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      value={dispForm?.alt_contact_number}
                      minLength={10}
                      maxLength={10}
                      onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("alt_contact_number", e.target.value) : {})}
                    />
                  </div>
                )}

                {["RTP"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Reason</div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Reason"
                      value={dispForm?.remark}
                      onChange={(e) => handleDispForm("remark", e.target.value)}
                    />
                  </div>
                )}

                {["PTP"].includes(dispForm?.disposition) && (
                  <div className="row-field space-mt-16">
                    <div className="filter-label space-mb-8">Date</div>
                    <DatePicker label="Select Date" value={dispForm?.ptp_date} onChange={(val) => handleDispForm("ptp_date", val)} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Close
          </Button>
          <Button disabled={!dispForm?.disposition || loading} onClick={() => addDisposition()} variant="primary">
            {loading ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
