import OnlineCaseStatus from "./online-case-status";
import CallResponse from "./call-response";
import WhatsappResponse from "./whatsapp-response";
import EmailResponse from "./email-response";
import Conciliation from "./conciliation";
import PaymentStatus from "./payment-status";
import SettlementStatus from "./settlement-status";
import SettlementConfirmation from "./settlement-confirmation";

export default function Analysis({ info }) {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }} className="col-12 gap-3">
      {/* Call Response */}
      <CallResponse />

      {/* Online Case Status */}
      <OnlineCaseStatus />

      {/* Whatsapp Response */}
      <WhatsappResponse />

      {/* Email Response */}
      <EmailResponse />

      {/* Conciliation */}
      <Conciliation info={info} />

      {/* Settlement Status */}
      <SettlementStatus />

      {/* Payment Status */}
      <PaymentStatus />

      {/* Settlement Confirmation */}
      <SettlementConfirmation />
    </div>
  );
}
